import React from "react";
import { Link } from 'react-router-dom';

const Footer = () => {
    return(
        <>
        <footer>
            <div className="footer">
                <div className="container-fluid">
                    <div className="row"> 
                        <div className="col-xl-3 col-lg-6 col-md-6">
                            <div className="quick-link">
                                <h3>Quick Links</h3>
                                <ul>
                                <li><Link to='/History'>History</Link></li> 
                                <li><Link to='/AimsObjectives'>Aims & Objectives</Link></li>  
                                <li><Link to="/GeneralInformation">School Information</Link></li>
                                <li><Link to='/Tc'>Transfer Certificate </Link></li> 
                                <li><Link to='/ErpSoftware'>Erp Software</Link></li> 
                                <li><Link to='/ParentPortal'>Parent  Guidelines</Link></li> 
                                <li><Link to='/ContactUs'>Contact Us</Link></li> 
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-6 col-md-6">
                            <div className="cont-dtl">
                                <h3>contact us</h3>
                                <p><img src="https://webapi.entab.info/api/image/SMSUM/public/Images/locicn.png" className="img-fluid"/> <span>St. Mary’s Convent Senior Sec. School, Azad Nagar, Dewas Road, Ujjain - M.P. 456010</span></p>
                                <p><img src="https://webapi.entab.info/api/image/SMSUM/public/Images/telicn.png" className="img-fluid"/> <span><a href="tel: 0734-2512771"><b>Secondary Section :</b> 0734-2512771</a><br /> <a href="tel: 0734-2514771"><b>Primary Section :</b> 0734-2514771</a></span></p>
                                <p><img src="https://webapi.entab.info/api/image/SMSUM/public/Images/mailicn.png" className="img-fluid" /><span> <a href="mailto: stmarysujn@rediffmail.com"><b>Principal :</b> stmarysujn@rediffmail.com</a> <br></br> <a href="mailto: stmarysujn@rediffmail.com"><b>Office :</b> stmarysujn@rediffmail.com</a><br /><a href="mailto: opinionsstmarysujn@gmail.com"><b>Enquiry/Opinions :</b> opinionsstmarysujn@gmail.com</a></span></p>
                                 
                            </div>
                        </div>
                        
                        <div className="col-xl-3 col-lg-6 col-md-6">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3668.0425149590656!2d75.79190758688465!3d23.168648356913124!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39637441f779fedd%3A0xefa1d8f12baca492!2sSt.Mary%E2%80%99s%20Convent%20Senior%20Sec.%20School%2C!5e0!3m2!1sen!2sin!4v1718167494216!5m2!1sen!2sin" width="100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                        <div className="col-xl-3 col-lg-6 col-md-6">
                            <div className="d-flex">
                                <div className="app-cont">
                                    <p>Download Mobile App<br></br> A Common Platform For Educators, Parents & Teachers</p>
                                    <div>
                                    <a href='https://apps.apple.com/in/app/campuscare-10x/id1611283814' target='_blank'> <img src="https://webapi.entab.info/api/image/SMSUM/public/Images/app1.png" /></a>
                            <a href='https://play.google.com/store/apps/details?id=com.entab.learninglab&hl=en-IN' target='_blank'> <img src="https://webapi.entab.info/api/image/SMSUM/public/Images/app2.png" /></a>
                                    </div>
                                    <p className="link">
                                        <a href="#">Use School Code :  </a>
                                    </p>
                                </div>
                                <div className="phone-img">
                                    <img src="https://webapi.entab.info/api/image/SMSUM/public/Images/app3.png" />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                
            </div>
            
            </footer>
        </>
    )
}
export default Footer;