import Slider from "react-slick";
import { useEffect, useState } from 'react'; 
import { getPrincipalMessage } from '../Service/Api';

const PrincipalMessage = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true); 
    console.log(data);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const principalData = await getPrincipalMessage();
                setData(principalData);
                
            } catch (error) {
                console.log("Data Error", error);
            } finally{
                setLoading(false)
            }
        };
        fetchData();
    }, []);

    const settings = {
        autoplay: true,
        infinite: true,
        autoplaySpeed: 2000,
        speed: 1000,
        slidesToShow:1,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: false, 
        adaptiveHeight: true
      };

    const emptyData = [
        { id: 1, category: "Designation", message: "St. Mary’s Convent Senior Secondary School Ujjain is run by the Franciscan Sisters of St. Mary of the Angels (F.S.M.A)  a religious Congregation dates back to 8th July 1964. Three young enthusiastic and zealous pioneers arrived at Ujjain to open an English Medium School in response to the demand of the local population and Mr. Tickoo, the then Collector of Ujjain. The School started officially functioning in August 1964 in a rented building at Freeganj with about 150 students on the roll. In the year 1966, the school was shifted to its present building." , name: "Name" },
        { id: 2, category: "Designation", message: "St. Mary’s Convent Senior Secondary School Ujjain is run by the Franciscan Sisters of St. Mary of the Angels (F.S.M.A)  a religious Congregation dates back to 8th July 1964. Three young enthusiastic and zealous pioneers arrived at Ujjain to open an English Medium School in response to the demand of the local population and Mr. Tickoo, the then Collector of Ujjain. The School started officially functioning in August 1964 in a rented building at Freeganj with about 150 students on the roll. In the year 1966, the school was shifted to its present building." , name: "Name" },
        { id: 3, category: "Designation", message: "St. Mary’s Convent Senior Secondary School Ujjain is run by the Franciscan Sisters of St. Mary of the Angels (F.S.M.A)  a religious Congregation dates back to 8th July 1964. Three young enthusiastic and zealous pioneers arrived at Ujjain to open an English Medium School in response to the demand of the local population and Mr. Tickoo, the then Collector of Ujjain. The School started officially functioning in August 1964 in a rented building at Freeganj with about 150 students on the roll. In the year 1966, the school was shifted to its present building." , name: "Name" }
    ];

    return (
        <>  
            <Slider className='owl-theme message-blk' loop margin={10} {...settings}>
                {data && data.length > 0 ? (data.map((item, index) => (
                    <div className="item" key={index}>
                        <div className="message-block">
                            <div>
                                <div className="message-title">
                                    {item.category}'s Message
                                </div>
                                <div className='message-content animateMe' data-animation="fadeInLeft">
                                    <p>{item.message}</p>
                                    <div className='button abt-button'>
                                        <a href="/PrincipalMessage">
                                            <img src="https://webapi.entab.info/api/image/SMSUM/public/Images/Read-more.png" alt="Read More" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className='message-image animateMe' data-animation="fadeInRight">
                                 <div class="message-image-first">
                                 <img src={`https://webapi.entab.info/api/image/${item.attachments}`} alt="Principal" className="img-fluid" />
                                 <div className="message-image-scd">
                                     <img src="https://webapi.entab.info/api/image/SMSUM/public/Images/principal-quo.png" className="quotes" alt="Quotes" />
                                 </div>  
                                <div className='princi-Name'>
                                        <h3>{item.name}</h3>
                                        <p>{item.category}</p>
                                    </div>
                                 </div> 
                            </div>
                        </div>
                    </div>
                ))) : (emptyData.map((data, index) => (
                    <div className="item" key={index}>
                        <div className="message-block">
                            <div>
                                <div className="message-title">
                                    {data.category}'s Message
                                </div>
                                <div className='message-content animateMe' data-animation="fadeInLeft">
                                     <p>{data.message}</p>
                                    <div className='button abt-button'>
                                        <a href="/PrincipalMessage">
                                            <img src="https://webapi.entab.info/api/image/SMSUM/public/Images/Read-more.png" alt="Read More" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className='message-image animateMe' data-animation="fadeInRight">
                                 <div class="message-image-first">
                                <img src="https://webapi.entab.info/api/image/SMSUM/public/Images/principal.jpg"  alt="Principal" className="img-fluid"/>
                                <div className="message-image-scd">
                                 <img src="https://webapi.entab.info/api/image/SMSUM/public/Images/principal-quo.png" className="quotes" alt="Quotes" />
                                 </div>  
                                 <div className='princi-Name'>
                                         <h3>{data.name}</h3>
                                         <p>{data.category}</p>
                                     </div>
                                  </div> 
                            </div>
                        </div>
                    </div>
                )))}
            </Slider>
        </>
    );
};

export default PrincipalMessage;
