 
import './App.css';
import { BrowserRouter , Route,  Routes } from 'react-router-dom'; 
import Home from './Pages/home'; 
import PrincipalMessage from'./Pages/PrincipalMessage'
import History from'./Pages/History'
import Facilities from'./Pages/Facilities'
import Activities from'./Pages/Activities'
import Admission from'./Pages/Admission'
import AimsObjectives from'./Pages/AimsObjectives'
import GeneralInformation from'./Pages/GeneralInformation'
import ContactUs from'./Pages/ContactUs'
import Tc from'./Pages/Tc' 
import ErpSoftware from'./Pages/ErpSoftware'
import VideoGallery from'./Pages/VideoGallery'
import News from'./Pages/News'
import Events from'./Pages/Events'
import PhotoGallery from'./Pages/PhotoGallery'
import SubGallery from'./Pages/SubGallery'
import ParentPortal from'./Pages/ParentPortal'
function App() {
  return (
    <div className="App"> 
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />} /> 
          <Route path="/PrincipalMessage" element={<PrincipalMessage/>} /> 
          <Route path="/History" element={<History/>} /> 
          <Route path="/Facilities" element={<Facilities/>} /> 
          <Route path="/Activities" element={<Activities/>} />
          <Route path="/Admission" element={<Admission/>} />
           <Route path="/AimsObjectives" element={<AimsObjectives/>} />
           <Route path="/GeneralInformation" element={<GeneralInformation/>} />
           <Route path="/ContactUs" element={<ContactUs/>} />
            <Route path="/Tc" element={<Tc/>} />
            <Route path="/ParentPortal" element={<ParentPortal/>} />
             <Route path="/ErpSoftware" element={<ErpSoftware/>} />
             <Route path="/VideoGallery" element={<VideoGallery/>} />
             <Route path="/News" element={<News/>} />
             <Route path="/Events" element={<Events/>} />
             <Route path="/PhotoGallery" element={<PhotoGallery/>} />
             <Route path="/SubGallery" element={<SubGallery/>} />
        </Routes> 
      </BrowserRouter>
    </div>
  );
}

export default App;