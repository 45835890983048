import React, { useEffect, useState } from 'react'; 
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import {getSliders} from'../Service/Api'
const SliderComponent = () => {
     const [data,setData] = useState([]);
     const [loading,setLoading] = useState(true);
      
      useEffect(() => {
            const fetchData = async () => {
                  try{
                     const sliderData = await getSliders();
                  } catch (error){
                       console.log("error")
                  } finally{
                    setLoading(false)
                  }

            }
            fetchData();
      },[]);
    const empty =[


    ]
    var settings = {
        loop: true,
        margin: 10, 
        autoplay: true,
        dots: true,
        items: 1 
    };

    return (
        <OwlCarousel className='owl-theme' loop margin={10} {...settings}>
        <div className="item">
             <img src='https://webapi.entab.info/api/image/SMSUM/public/Images/sld1.png' />
         </div>
           { /*  {data && data.length > 0 ? (data.map((item,index) => (
                <div className="item" key={index}>
                    <img src={`https://webapi.entab.info/api/image/${item.images}`} />
                </div>
            ))) : (empty.map((item) => (
                <div className="item" key={item.id}>
                    <img src='https://webapi.entab.info/api/image/SMSUM/public/Images/sld1.png' />
                </div>
            )))} */ }
             
        </OwlCarousel>
    );
};

export default SliderComponent;
