import React, { useEffect, useState } from "react"; 
import { getNews } from "../Service/Api";
import { Link } from "react-router-dom";
const News = () => {
    
    const [data,setData] = useState([]);
    const [loading,setLoading] = useState(true);
    const [error,setError] = useState(null);

    useEffect(() =>{
          const fetchData = async () => {
             try {
                const response = await getNews();
                 setData(response);
             }catch(error){
                setError(error)
             }finally {
                 setLoading(false);
             } 
          };
          fetchData();
    },[]);
 

    const empty = [
        {id:1,title:"Stay Tunned For More Updates"},
        {id:2,title:"Stay Tunned For More Updates"},
        {id:3,title:"Stay Tunned For More Updates"}
      ]
    return (
        <>
        <marquee onMouseOver={(event) => event.currentTarget.stop()} direction="up"  onMouseOut={(event) => event.currentTarget.start()}>
          
         
              {data && data.length > 0 ? (
                data.map((item) =>(
                    <div className='newsarea' key={item.id}>
                            <div>
                                <p><b>{item.title}</b></p>
                                <p className='newshead'>{item.description}</p>
                            </div>
                    
                            <Link to={`https://webapi.entab.info/api/image/${item.attachments}`} target='_blank'>
                               <img src="https://webapi.entab.info/api/image/SMSUM/public/Images/attachment.png" />
                            </Link> 
                     </div>    
                )) 
              ) : (
                 empty.map((item) =>(
                    <div className='newsarea' key={item.id}>
                            <div>
                                <p><b>{item.title}</b></p>
                                <p className='newshead'>{item.description}</p>
                            </div>
                    
                            <Link to="#" target='_blank'>
                               <img src="https://webapi.entab.info/api/image/SMSUM/public/Images/attachment.png" />
                            </Link> 
                     </div>    
                ))  
              )}



         {  /*  <div className='newsarea'> <div> <p><b>Summer Holiday Notice</b></p> <p className='newshead'>Summer Vacation</p> </div> <a href="#" target='_blank'> <img src="https://webapi.entab.info/api/image/SMSUM/public/Images/attachment.png" /> </a> </div> */}
                 
            </marquee>
        </>
    );
};

export default News;
