import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getHighlight } from '../Service/Api';

const Highlights = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getHighlight();
        setData(result);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

 
  
  const empty = [
     {id:1,title:"Stay Tunned For More updates"},
     {id:2,title:"Stay Tunned For More updates"},
     {id:3,title:"Stay Tunned For More updates"}
  ]
  return (
    <>
       
        <marquee
          onMouseOver={(event) => event.currentTarget.stop()}
          direction="up"
          onMouseOut={(event) => event.currentTarget.start()}
        >
        {data && data.length > 0 ? (
          data.map((item) => (
            <p key={item.id}>
              {item.title}
              <Link to={`https://webapi.entab.info/api/image/${item.attachments[0]}`} target="_blank">
                <img src="https://webapi.entab.info/api/image/SMSUM/public/Images/attachment.png" alt="Attachment" />
             </Link>
            </p>
          ))
        ) : (
          empty.map((item) => (
            <p key={item.id}>
              {item.title}
              <Link to="#" target="_blank">
                <img src="https://webapi.entab.info/api/image/SMSUM/public/Images/attachment.png" alt="Attachment" />
             </Link>
            </p>
          ))
        )}
        </marquee>
      
    </>
  );
};

export default Highlights;
