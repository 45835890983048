import React, { useEffect, useState } from "react"; 
import {getAllBirthdays} from'../Service/Api' 

const AllBirthdays = () => {
     const [data,setData] = useState([]);
     const [loading, setLoading] = useState(true); 
        
       useEffect(() => {
            const fetchData = async () => {
                try{
                   const birthdayData = await getAllBirthdays();
                   setData(birthdayData);
                } catch (error) {
                   console.log("error Fetching data ",error)
                } finally{
                    setLoading(false)
                  }
            }
            fetchData();
       },[]);
    const emppty =[]
  
      const emptyData = [
        { id: 1,},
        { id: 2,},
        { id: 3,},
        { id: 4,},
        { id: 5,},
        { id: 6,}
    ];
    return (
        <>
        <div className='col-md-12 col-lg-6'>
        <div className="upcoming addColor"> 
            <h4>Birthday falls<br></br> in a Month</h4>
        </div> 
        <div className="birthday-flex">
                   {data && data.length > 0 ? (data.map((item,index) => ( 
                    <div className="item" key={index}> 
                    <div className="birthday-content">
                    <img src={`https://webapi.entab.info/api/image/${item.attachments[0]}`} />
                        <p>{item.name}</p>
                        <p class="primary">{item.date}</p>
                        <p class="primary">{item.class}</p>
                    </div>
                </div>
                 ))) : (emptyData.map((item) => ( 
                    <div className="item" key={item.id}>
                        <div className="birthday-content">
                            <img src="https://webapi.entab.info/api/image/SMSUM/public/Images/birthday3.png" />
                              <p>Student Name</p>
                            <p class="primary">Date</p>
                            <p class="primary">Class</p>
                        </div>
                    </div>
                    
                 )))}
                      
                </div>
            </div>
   

      {/* 
            <div className='col-md-12 col-lg-6'>
                <div className="upcoming addColor"> 
                    <h4>Birthday falls<br></br> in a Month</h4>
                </div> 
                <div className="birthday-flex">
                    <div className="item">
                        <div className="birthday-content">
                            <img src="https://webapi.entab.info/api/image/SMSUM/public/Images/birthday3.png" />
                            <p>Chandresh Srivastava</p>
                            <p class="primary">12 June</p>
                            <p class="primary">VI - B</p>
                        </div>
                    </div>
                    <div className="item">
                        <div className="birthday-content">
                            <img src="https://webapi.entab.info/api/image/SMSUM/public/Images/birthday4.png" />
                            <p>Prakhar Jain</p>
                            <p class="primary">12 June</p>
                            <p class="primary">VI - B</p>
                        </div>
                    </div>
                    <div className="item">
                        <div className="birthday-content">
                            <img src="https://webapi.entab.info/api/image/SMSUM/public/Images/birthday1.png" />
                            <p>Vishwajeet Vidyarthi</p>
                            <p class="primary">12 June</p>
                            <p class="primary">VI - B</p>
                        </div>
                    </div> 
                    <div className="item">
                        <div className="birthday-content">
                            <img src="https://webapi.entab.info/api/image/SMSUM/public/Images/birthday4.png" />
                            <p>Bhagyashree Vishwakarma</p>
                            <p class="primary">12 June</p>
                            <p class="primary">VI - B</p>
                        </div>
                    </div>
                    <div className="item">
                        <div className="birthday-content">
                            <img src="https://webapi.entab.info/api/image/SMSUM/public/Images/birthday3.png" />
                            <p>Nishank Awasthi</p>
                            <p class="primary">12 June</p>
                            <p class="primary">VI - B</p>
                        </div>
                    </div>  
                    <div className="item">
                        <div className="birthday-content">
                            <img src="https://webapi.entab.info/api/image/SMSUM/public/Images/birthday1.png" />
                            <p>Vishwajeet Vidyarthi</p>
                            <p class="primary">12 June</p>
                            <p class="primary">VI - B</p>
                        </div>
                    </div> 
                    </div>
                 </div> */ }

        </>
    )
}
export default AllBirthdays